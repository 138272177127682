import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'


//IMG
import logo2 from '../assets/img/logo2.png'
// import arrowgreen from '../assets/img/arrowgreen.svg'

// import img1 from '../assets/img/imgslider3.png'
import img2 from '../assets/img/imgslider2.png'
// import img3 from '../assets/img/imgslider3.png'
// import img4 from '../assets/img/slider4.png'
import imgslider3 from '../assets/img/imgslider3.png'


// import icon1 from '../assets/img/icon1.png'
// import icon2 from '../assets/img/icon2.png'
// import icon3 from '../assets/img/icon3.png'

// import img5 from '../assets/img/5.png'
// import img6 from '../assets/img/6.png'
// import img7 from '../assets/img/7.png'
// import img8 from '../assets/img/8.png'
// import shop from '../assets/img/shop_white.png'

import ocana from '../assets/img/img4.jpg'


export const Inicio = () => {

	useEffect(() => {
		document.title = "Ocaña formación: Preparación de oposiciones";
	  }, []);

	useEffect(() => {
		window.document.documentElement.lang = "es";
	  }, []);

	const { lang } = useParams();//parametros de la url

	const refArr = useRef([]); //Array de referencias a los elementos que se quieren animar

	const [scrollY, setScrollY] = useState(0); //Almacena el valor del scroll y siempre es incrementado

	/**
	 * Actualiza el estado scrollY con el valor del scroll
	 */
	const handleScroll = () => {
		setScrollY((prev) => window.scrollY > prev ? window.scrollY : prev);
	};

	/**
	 * Comprueba si el elemento es visible en la pantalla
	 * @param {integer} idx indice en el array de referencias del elemento a comprobar
	 * @returns {string} "visible" o "" para activar la animacion
	 */
	const isVisible = (idx) => {
		return (refArr?.current[idx]?.offsetTop - 700 < scrollY) ? "visible" : ""
	}

	/**
	 * Añade el listener del scroll al montar el componente
	 */
	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		// Eliminar listener cuando el componente se desmonta
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<>


			<div className="inicio">
				<div className="col-12 slider">

					<div className="col-6">
						<img src={logo2} alt="" />
					</div>
					<div className="col-6">
						<h2>
							Si te atreves a enseñar,<br/>
							no dejes de aprender.
						</h2>
						<ul className='listaslider'>
							<li className='active'>
								<a href="#oformacion" target='_self'>Ocaña formación</a>
							</li>
							<li>
								<a href="#equipo" target='_self'>Quiénes somos</a>
							</li>
							<li>
								<a href="#modalidades" target='_self'>Modalidades</a>
							</li>
							<li>
								<a href="#testimonios" target='_self'>Testimonios</a>
							</li>
							<li>
								<NavLink to={{ pathname: `/${lang}/contacto` }} onClick={()=>{window.scrollTo(0, 0)}}>Contacto</NavLink>
							</li>
					
						</ul>

					</div>

				</div>
			</div>

			<div className="col-12 item2 anima grey" id="oformacion">
				<div className="col-12" ref={el => refArr.current[0] = el}>
					<div className={"col-6 " + isVisible(0)}>
						<img src={img2} alt="" className="sliderimg3" />
					</div>
					<div className={"col-6 " + isVisible(0)}>

						<h2 className='verde'>
							Ocaña formación
						</h2>

						<p>
							Somos un centro de formación con más de 15 años de experiencia en la
							preparación de oposiciones de magisterio.
						</p>	
						<p className='fixbold'>
							¿Cuál es la clave de nuestro éxito?
						</p>	
						<p>
						100% de aprobados y opositores con más de un 9 de nota media, los que nos hace ser los primeros de cada tribunal y conseguir así la plaza de maestro/a en la función pública.
						</p>
						<p>
						Nos caracterizamos por nuestra dedicación y compromiso, acompañándote durante todo el proceso de una manera individual y personalizada.
						</p>
						<p>
							Te presentamos nuestro equipo formativo.
						</p>
					
						<div className="col-12 btns">							
							<a href="#equipo" target='_self'  className="btna green mgtop15">Nuestro equipo</a>
						</div> 
					</div>
				</div>
			</div>

			<div className="col-12 item1 fdverde2" id="equipo">
				<div className="col-12 jccenter">				
						<h2 className='verde'>
							Quiénes somos
						</h2>
						<h3>Nuestro equipo está formado por maestros/as apasionados por su profesión, que han sido miembros de tribunales de oposición y que tienen años de experiencia en la preparación de oposiciones. Preparamos las especialidades de Educación Infantil, Educación Primaria, Educación Física y francés con apoyo de un profesor nativo para obtener los mejores resultados en la exposición oral.</h3>						
					</div>
			</div> 		

			<div className="col-12 item2 anima grey" id="modalidades">
				<div className="col-12 jccenter">				
					<div className={"col-6 " + isVisible(0)}>
						<h2 className='verde tleft'>
						Modalidades de preparación
						</h2>
						<p className='tleft'>Disponemos de 2 modalidades de preparación tanto presencial como online, ambas totalmente  personalizadas en grupos reducidos y con tutorización directa de nuestros preparadores.</p>
						<ul className='listapreparacion tleft'>
								<li>
									<p className='tleft'><span className='fw600'>Preparación completa para nuevo alumnado:</span> incluye temario, supuestos prácticos, propuesta pedagógica y unidades didácticas con situaciones de aprendizaje. Realizamos numerosos simulacros tanto de la parte escrita como de la parte oral. Las clases se imparten de manera online y/o presencial una vez a la semana.</p>
								</li>
								<li>
									<p className='tleft'><span className='fw600'>Preparación avanzada para alumnado matriculado en años anteriores:</span> incluye todas las novedades y modificaciones, así como simulacros tanto de la parte escrita como de la parte oral. Las clases se imparten de manera online y/o presencial una vez al mes.</p>
								</li>									
						</ul>
					</div>
					<div className={"col-6 " + isVisible(0)}>
						<img src={imgslider3} alt="" className="sliderimg3" />
					</div>
				</div>
			</div> 		 	 	

			<div className="col-12 item1 anima" id="testimonios">
				<div className="col-12">
						<h2 className='verde'>
							Testimonios
						</h2>
						<p>
						Algunos de nuestros alumnos te cuentan su experiencia.
						</p>
				</div>
				<div className="col-12 listatestimonios">
						<div className='col-3'>
							<h3>Elena Ceacero</h3>
							<b>Obtuvo plaza a la primera.</b>
							<p>“Conseguí mi plaza a la primera gracias a Luisa, sin tiempo de servicio. La
							preparación fue muy completa y nos ayudó en todo momento. Te asesora
							también para el baremo.La recomiendo 100%.”</p>
						</div>
						<div className='col-3'>
							<h3>Raquel Jiménez</h3>
							<b>Primera de su tribunal y puesto veinticuatro de toda Andalucía</b>
							<p>“Mi preparadora ha estado ahí en cada momento con consejos, palabras optimistas y ayuda incondicional.Nunca podré agradecerle lo suficiente el haberme ayudado a conseguir mi sueño: mi plaza.”</p>
						</div>
						<div className='col-3'>
							<h3>María del Carmen Espinosa</h3>
							<b>Número 1 en su tribunal.</b>
							<p>“En 2022 conseguí mi ansiada plaza, siendo la primera de mi tribunal con un 9,66 de media en ambas partes. Si tu sueño es obtener tu plaza, sin duda estás en el lugar correcto. Lo recomiendo 100%.”</p>
						</div>
				</div>
			</div>
		</>
	)
}
